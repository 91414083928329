<template>
  <div class="advReport">
    <!-- <div class="main-Title bgff"><h2>Adv/Source Report</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form v-model="filter" size="mini">
          <el-row>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="country" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input v-model="filter.country" placeholder="US"></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="countryChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="appId" label-width="1.2rem" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="filter.appId"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="appIdChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="os" label-width="1.2rem" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select v-model="filter.os" placeholder="Please select" class="w100">
                      <el-option
                        v-for="item in options.os"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="osChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="eventName" label-width="2.0rem" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="filter.eventName"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="eventNameChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="4" :lg="6" :xl="6" style="height: 34px;">
              <el-form-item label="Date" label-width="55px" class="mb5">
                <el-date-picker
                  v-model="startToEndDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  class="w100"
                  align="right"
                  unlink-panels
                  :clearable="false"
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2" align="right">
              <el-button
                type="primary"
                size="mini"
                :loading="listLoading"
                @click="searchListAllpbReport(1)"
                >In Browser
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          style="width:100%;"
          size="mini"
          stripe
          border
          ref="tableRef"
          :data="allpbReportList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          @sort-change="sortChange"
          highlight-current-row
        >
          <el-table-column
            label="Date"
            prop="day"
            v-if="dateChecked"
            align="center"
            key="day"
            sortable="custom"
          ></el-table-column>

          <el-table-column
            label="appId"
            prop="appId"
            align="center"
            v-if="appIdChecked"
            key="appId"
          ></el-table-column>
          <el-table-column
            label="Country"
            prop="country"
            align="center"
            v-if="countryChecked"
            key="country"
          ></el-table-column>
          <el-table-column
            label="os"
            prop="os"
            v-if="osChecked"
            align="center"
            key="os"
          ></el-table-column>

          <el-table-column
            label="eventName"
            prop="eventName"
            v-if="eventNameChecked"
            align="center"
            key="eventName"
          ></el-table-column>

          <el-table-column
            label="deviceCount"
            prop="deviceCount"
            align="center"
            key="deviceCount"
          ></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageParam.pageSize"
          :currentPage="pageParam.pageNum"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
  import AdvReportCon from '../../controllers/DDJ/allpbReport';

  export default {
    name: 'AdvReport',
    ...AdvReportCon,
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
