import { getAllpbReport } from 'api/report';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { cloneDeep } from 'lodash-es';
import dateUtils from '@/utils/dateutils.js';
import { myMixin } from '@/mixins/mixins.js';
import optionData from '@/assets/js/optionData';
//import request from "utils/request";
export default {
  mixins: [myMixin],
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      pageParam: {
        pageNum: 1,
        pageSize: 20,
      },
      dataParam: null,
      osChecked: false,
      eventNameChecked: false,
      countryChecked: false,
      dateChecked: true,
      appIdChecked: false,
      startToEndDate: '',
      listLoading: false,
      total: null,
      fullWidth: document.documentElement.clientWidth,
      summariesMap: {},
      filter: {
        countries: null,
        appId: null,
        os: null,
        fromDate: null,
        toDate: null,
        columns: [],
        sorting: 'deviceCount',
      },
      options: {
        country: [],
        os: optionData.osOption,
      },
      allpbReportList: [],
    };
  },
  computed: {},
  watch: {
    listenChange: function() {
      this.$nextTick(() => {
        this.$refs.tableRef.doLayout();
      });
    },
  },
  mounted() {
    //默认选择
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() + 3600 * 1000 * 24);
    this.startToEndDate = [];
    this.startToEndDate[0] = dateUtils.date2StrYMD(end);
    this.startToEndDate[1] = dateUtils.date2StrYMD(start);
  },
  methods: {
    filterNull(val) {
      return val == null ? 0 : val;
    },
    searchListAllpbReport(curPage) {
      if (curPage) {
        this.pageParam.pageNum = curPage;
      }
      const param = {
        ...this.filter,
        ...this.pageParam,
      };
      if (this.countryChecked == true && param.columns.indexOf('country') === -1) {
        param.columns.push('country');
      } else if (this.countryChecked == false && param.columns.indexOf('country') !== -1) {
        const countryIndex = param.columns.findIndex((item) => {
          if (item == 'country') {
            return true;
          }
        });
        param.columns.splice(countryIndex, 1);
      }
      if (this.appIdChecked == true && param.columns.indexOf('app_id') == -1) {
        param.columns.push('app_id');
      } else if (this.appIdChecked == false && param.columns.indexOf('app_id') !== -1) {
        const carrierIndex = param.columns.findIndex((item) => {
          if (item == 'app_id') {
            return true;
          }
        });
        param.columns.splice(carrierIndex, 1);
      }

      if (this.osChecked == true && param.columns.indexOf('os') == -1) {
        param.columns.push('os');
      } else if (this.osChecked == false && param.columns.indexOf('os') !== -1) {
        const osIndex = param.columns.findIndex((item) => {
          if (item == 'os') {
            return true;
          }
        });
        param.columns.splice(osIndex, 1);
      }

      if (this.eventNameChecked == true && param.columns.indexOf('event_name') == -1) {
        param.columns.push('event_name');
      } else if (this.eventNameChecked == false && param.columns.indexOf('event_name') !== -1) {
        const eventNameIndex = param.columns.findIndex((item) => {
          if (item == 'os') {
            return true;
          }
        });
        param.columns.splice(eventNameIndex, 1);
      }

      if (this.dateChecked == true && param.columns.indexOf('day') === -1) {
        param.columns.push('day');
      } else if (this.dateChecked == false && param.columns.indexOf('day') !== -1) {
        const dateIndex = param.columns.findIndex((item) => {
          if (item == 'date') {
            return true;
          }
        });
        param.columns.splice(dateIndex, 1);
      }

      if (this.startToEndDate !== null && this.startToEndDate.length === 2) {
        let startDate = this.startToEndDate[0];
        let endDate = this.startToEndDate[1];
        param.fromDate = startDate;
        param.toDate = endDate;
      }

      this.pageParam.pageNum = 1;
      const dataParam = cloneDeep(param);
      if (Array.isArray(dataParam.columns)) {
        dataParam.columns = dataParam.columns.join(',');
      }
      this.dataParam = dataParam;
      this.getAllpbReport();
    },
    getAllpbReport() {
      this.listLoading = true;
      let param = {
        ...this.dataParam,
        ...this.pageParam,
      };
      getAllpbReport(param)
        .then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            if (response.result) {
              if (response.result.records) {
                const summariesMap = response.result.records.pop() || [];
                const allpbReportList = response.result.records;
                this.summariesMap = summariesMap;
                const totalObject = new Object();
                totalObject.day = 'Total';
                totalObject.deviceCount = this.summariesMap.deviceCount;
                this.allpbReportList = allpbReportList;
                this.allpbReportList.push(totalObject);
              } else {
                this.allpbReportList = [];
              }
              this.total = response.result.total;
            } else {
              this.allpbReportList = [];
            }
          } else {
            this.allpbReportList = [];
            this.$message.error(response.message);
          }
        })
        .catch((e) => {
          this.listLoading = false;
          this.$message.error(e);
        });
    },
    handleSizeChange(size) {
      this.pageParam.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.pageParam.pageNum = currentPage;
      this.getAllpbReport();
    },
    // 排序监听
    sortChange(column) {
      console.log(column);
      if (column.prop !== null && column.order !== null) {
        this.filter.sorting = column.prop;
        this.filter.sortType = column.order == 'ascending' ? 'asc' : 'desc';
      } else {
        this.filter.sorting = '';
        this.filter.sortType = '';
      }
      this.searchListAllpbReport('Search');
    },
  },
};
